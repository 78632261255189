import { useEffect } from 'react';
import rocketmanTransport from '../services/RocketmanTransport';

const useTabVisibility = () => {

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        rocketmanTransport.setActiveTab(false);
      } else {
        rocketmanTransport.setActiveTab(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default useTabVisibility;

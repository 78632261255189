// const provablyFairFormatter = (hashStringOriginal, rtp) => {
//   const hashStringTrimmed = hashStringOriginal.slice(0, 13);
//   const trimmedForExport = hashStringTrimmed;

//   // console.log(`("First 13 chars of HASH: {}" ${hashStringTrimmed}`);

//   const hex_to_int = parseInt(hashStringTrimmed, 16);
//   // console.log(`Decimal value of HASH: {} ${hex_to_int}`);

//   const x = 99 / (1 - (hex_to_int / (2 ** 52)));     // Math.pow(2,52);
//   const number = Math.max(1, Math.floor(x) / 100);          // last two digits of X represent decimals; if X is less than 1, return 1;

//   // console.log(`Raw Multiplier: {} ${number}`);

//   const multiplier = parseFloat(number * (rtp / 100)).toFixed(2);

//   // console.log(`Edged Multiplier: {} ${multiplier}`);

//   // HEX to DEC Manual Conversion

//   //  HEX TO DEC
//   //  A = 10
//   //  B = 11
//   //  C = 12
//   //  D = 13
//   //  E = 14
//   //  F = 15

//   const hashArray = hashStringTrimmed.split('');

//   for (let i in hashArray) {
//     if (hashArray[i] == 'A') hashArray[i] = 10;
//     if (hashArray[i] == 'B') hashArray[i] = 11;
//     if (hashArray[i] == 'C') hashArray[i] = 12;
//     if (hashArray[i] == 'D') hashArray[i] = 13;
//     if (hashArray[i] == 'E') hashArray[i] = 14;
//     if (hashArray[i] == 'F') hashArray[i] = 15;

//     if (typeof hashArray[i] === 'string') {
//       hashArray[i] = parseInt(hashArray[i]);
//     }
//   }
//   let dec = {};

//   dec[0] = (hashArray[0] * (16 ** 12));
//   dec[1] = (hashArray[1] * (16 ** 11));
//   dec[2] = (hashArray[2] * (16 ** 10));
//   dec[3] = (hashArray[3] * (16 ** 9));
//   dec[4] = (hashArray[4] * (16 ** 8));
//   dec[5] = (hashArray[5] * (16 ** 7));
//   dec[6] = (hashArray[6] * (16 ** 6));
//   dec[7] = (hashArray[7] * (16 ** 5));
//   dec[8] = (hashArray[8] * (16 ** 4));
//   dec[9] = (hashArray[9] * (16 ** 3));
//   dec[10] = (hashArray[10] * (16 ** 2));
//   dec[11] = (hashArray[11] * (16 ** 1));
//   dec[12] = (hashArray[12] * (16 ** 0));

//   // console.log("dec[0]", dec[0])

//   //     4222124650659840     (15 * (16 ** 12 ))
//   //  +   263882790666240     (15 * (16 ** 11))
//   //  +   14293651161088  (13 * (16 ** 10 ))
//   //  +   137438953472
//   //  +   12884901888
//   //  +   536870912
//   //  +   50331648
//   //  +   1048576
//   //  +   131072
//   //  +   45056
//   //  +   3328
//   //  +   144
//   //  +   5

//   // console.log("\n\nHex to Decimal Conversion:");
//   // console.log(`${dec[0]}(${hashArray[0]} * (16 ** 12 ))`);
//   // console.log(`${dec[1]}(${hashArray[1]} * (16 ** 12 ))`);
//   // console.log(`${dec[2]}(${hashArray[2]} * (16 ** 12 ))`);
//   // console.log(`${dec[3]}(${hashArray[3]} * (16 ** 12 ))`);
//   // console.log(`${dec[4]}(${hashArray[4]} * (16 ** 12 ))`);
//   // console.log(`${dec[5]}(${hashArray[5]} * (16 ** 12 ))`);
//   // console.log(`${dec[6]}(${hashArray[6]} * (16 ** 12 ))`);
//   // console.log(`${dec[7]}(${hashArray[7]} * (16 ** 12 ))`);
//   // console.log(`${dec[8]}(${hashArray[8]} * (16 ** 12 ))`);
//   // console.log(`${dec[9]}(${hashArray[9]} * (16 ** 12 ))`);
//   // console.log(`${dec[10]}(${hashArray[10]} * (16 ** 12 ))`);
//   // console.log(`${dec[11]}(${hashArray[11]} * (16 ** 12 ))`);
//   // console.log(`${dec[12]}(${hashArray[12]} * (16 ** 12 ))`);
//   // console.log(`={} ${hex_to_int}`);

//   return {
//     trimmedForExport,
//     hashArray,
//     multiplier,
//     dec,
//     x
//   }
// }

// export default provablyFairFormatter;

const provablyFairFormatter = (hashStringOriginal, rtp) => {
  const hashStringTrimmed = hashStringOriginal.slice(0, 13);

  const trimmedForExport = hashStringTrimmed;

  const d = parseInt(hashStringTrimmed, 16); // Convert hash to decimal

  const rawMultiplier = (Math.pow(2, 52) - 1) / d; // Updated formula

  const roundedRawMultiplier = parseFloat(rawMultiplier.toFixed(2)); // Round to 2 decimals

  // const multiplier = Math.min(
  //   20000,
  //   Math.max(1, Math.floor(roundedRawMultiplier * (rtp / 100) * 100) / 100)
  // );

  let multiplier = roundedRawMultiplier * (rtp / 100);

// korak 6: Ensure the multiplier is at least 1
multiplier = Math.max(1, multiplier);

// korak 7: Ensure the multiplier does not exceed 20,000
multiplier = Math.min(20000, multiplier);

// korak 8: Apply flooring and divide by 100
multiplier = Math.floor(multiplier * 100) / 100;

  const hashArray = hashStringTrimmed.split("");

  for (let i in hashArray) {
    if (hashArray[i] == "A") hashArray[i] = 10;
    if (hashArray[i] == "B") hashArray[i] = 11;
    if (hashArray[i] == "C") hashArray[i] = 12;
    if (hashArray[i] == "D") hashArray[i] = 13;
    if (hashArray[i] == "E") hashArray[i] = 14;
    if (hashArray[i] == "F") hashArray[i] = 15;

    if (typeof hashArray[i] === "string") {
      hashArray[i] = parseInt(hashArray[i]);
    }
  }
  let dec = {};

  dec[0] = hashArray[0] * 16 ** 12;
  dec[1] = hashArray[1] * 16 ** 11;
  dec[2] = hashArray[2] * 16 ** 10;
  dec[3] = hashArray[3] * 16 ** 9;
  dec[4] = hashArray[4] * 16 ** 8;
  dec[5] = hashArray[5] * 16 ** 7;
  dec[6] = hashArray[6] * 16 ** 6;
  dec[7] = hashArray[7] * 16 ** 5;
  dec[8] = hashArray[8] * 16 ** 4;
  dec[9] = hashArray[9] * 16 ** 3;
  dec[10] = hashArray[10] * 16 ** 2;
  dec[11] = hashArray[11] * 16 ** 1;
  dec[12] = hashArray[12] * 16 ** 0;

  return {
    trimmedForExport,
    hashArray,
    rawMultiplier: roundedRawMultiplier,
    multiplier,
    x: d,
    dec
  };
};

export default provablyFairFormatter;
